export const APP_TITLE = "CITE";
export const APP_DESCRIPTION =
	"Crosstalk Interactions within Tumor microEnvironment";

export const URL_NAVBAR_LOGO = require("../assets/logo.svg");

export const ExampleQueryParams = ["AREG", "EGFR", "GBM", "LGG"];

export const Members: {
	imageUrl: string;
	header: string;
	subHeader: string;
	jobDescription: string;
	email: string;
}[] = [
	{
		imageUrl:
			"https://react.semantic-ui.com/images/avatar/large/matthew.png",
		header: "Probhonjon Baruah",
		subHeader: "Joined in 2019",
		jobDescription: "Bioinformatics Specialist",
		email: "baruahp@gis.a-star.edu.sg",
	},
	{
		imageUrl: "https://react.semantic-ui.com/images/avatar/large/molly.png",
		header: "Neha Rohatgi",
		subHeader: "Joined in 2017",
		jobDescription: "Post-Doc",
		email: "rohatgin@gis.a-star.edu.sg",
	},
	{
		imageUrl:
			"https://react.semantic-ui.com/images/avatar/large/daniel.jpg",
		header: "Anders Skanderup",
		subHeader: "Joined in 2015",
		jobDescription: "Principal Investigator",
		email: "skanderupamj@gis.a-star.edu.sg",
	},
];
